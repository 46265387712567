<template>
  <v-container id="plan-view"  v-bind:class="{'pa-0': $vuetify.breakpoint.smAndDown}">
    <v-scroll-x-transition mode="in-out" hide-on-leave>

        <NextDaysCalendar v-model="date" :maxDays="2" 
                    @input="updateDate" :loading="loading" :workouts="past7daysworkouts" 
                    :schedules="past6daysSchedules" 
                    
                    />
    </v-scroll-x-transition>

    <v-divider v-if="!$store.state.isDark"/>
   <!--  <v-scroll-x-transition mode="in-out" hide-on-leave>
      <v-card v-show="user && !user.planification" elevation="0" rounded="0" >
            

        <v-row  id="loaderDays"   no-gutters class="mb-4">
          <v-col cols="12" class="pt-4 pb-4 ml-2">
              <v-skeleton-loader key="skel" type="heading" ></v-skeleton-loader>
          </v-col>
        <v-col cols="2">
          <v-skeleton-loader type="avatar"></v-skeleton-loader>
        </v-col>

        <v-col cols="2">
          <v-skeleton-loader type="avatar"></v-skeleton-loader>
        </v-col>

        <v-col cols="2">
          <v-skeleton-loader type="avatar"></v-skeleton-loader>
        </v-col>

        <v-col cols="2">
          <v-skeleton-loader type="avatar"></v-skeleton-loader>
        </v-col>

        <v-col cols="2">
          <v-skeleton-loader type="avatar"></v-skeleton-loader>
        </v-col>

        <v-col cols="2">
          <v-skeleton-loader type="avatar"></v-skeleton-loader>
        </v-col>

      </v-row>
      </v-card>

    

    </v-scroll-x-transition> -->
    <v-scroll-x-transition mode="in-out" hide-on-leave>

    <v-alert type="success" text  class="ma-4 "
          v-show="workedOut && isSameDay"
          >
                    ¡Excelente! Has completado tu entrenamiento de hoy.
          </v-alert>

    </v-scroll-x-transition>
      <v-card elevation="0" class="ma-4 overflow-hidden" :outlined="$store.state.isDark? false:true"> 
          <v-card-text class="pa-0 ">
              <v-alert prominen class="mb-0" text type="info" v-if="info && !loading " @input="alert = false" >
            {{ info }}
          </v-alert>    

    <v-scroll-x-transition mode="in-out" hide-on-leave>
    <v-chip-group multiple   v-model="filterTags"  class="pl-2" key="chips" v-show="!info && !loading  && selected==null">
        <v-chip 
          color="secondary"
                :value="reducedTag"
         filter v-for="reducedTag in reducedTags"  :key="reducedTag" @click="selected = null">
  
          {{ reducedTag }}
        </v-chip>
      </v-chip-group>
    </v-scroll-x-transition>

    <v-scroll-x-transition group mode="in-out" hide-on-leave>
       <v-list  :key="'list'" class="ma-1 py-0 rounded-lg overflow-hidden glass" v-show="!info && !loading && selected==null" >
      <template  v-for="(planElem, iterIndex) in filteredRoutines"> 
    <v-list-item  three-line @click="selectPlan(iterIndex, planElem)"   :style="{ 'border-left': '2px solid ' + planElem.color +' !important' }"
       :key="iterIndex">
        <v-list-item-content>
          <v-list-item-title>
            {{ planElem.name }}
            </v-list-item-title>
          <v-list-item-subtitle>{{ planElem.description }}</v-list-item-subtitle>
          <v-list-item-subtitle>
            <v-chip-group multiple mandatory>
              <v-chip v-for="(type, index) in planElem.tag" :key="index" x-small>
                {{ type }}
              </v-chip>
            </v-chip-group>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action  v-if="user.planification == planElem.id">
              <v-chip small color="primary" v-if="!workedOut" class="rounded-lg">
               <v-icon left>mdi-chevron-right</v-icon>
               Principal
              </v-chip>
              <v-chip small  v-if="workedOut" class=" glass rounded-lg">
                <v-icon left>mdi-check</v-icon> 
               Entrenado
              </v-chip>

        </v-list-item-action>
        
        


      </v-list-item>
      <v-divider  v-show=" filterTags.length == 0 || planElem.tag.some(tag => filterTags.includes(tag)) && iterIndex < allRoutines.length-1"></v-divider>
      </template>
    </v-list>


    
          <v-skeleton-loader key="skel" type="list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar,actions" v-show="loading" class="px-2 py-1 pt-2"></v-skeleton-loader>
        <v-card elevation="0" rounded="0"  v-if="!loading && !info" class="ma-0" v-for="(planElem, iterIndex) in allRoutines" :key="iterIndex + 'rand'" 
      :id="planElem.name.replace(/\s/g, '-')" v-show="selected === iterIndex && !loading">

      <v-card-title class="pb-0" v-if="iterIndex == selected && allRoutines.length>1">
        <v-spacer ></v-spacer>
        <v-btn @click="selected = null" text small v-if="allRoutines.length>1">
          <v-icon left>
            mdi-chevron-up
          </v-icon>
          Volver al listado
        </v-btn>
        <v-spacer></v-spacer>

      </v-card-title>
   




     
      <v-card-title id="v-step-0" v-if="title">
  {{ title  ||  planElem.name}}
  <template
    v-if="user.planification == planElem.id
    "
  >
    <v-divider class="mx-2" v-show="(planElem.type == 'calendar')"></v-divider>
    <span class="caption" id="v-step-1" v-show="(planElem.type == 'calendar')"
      >Sem {{ planificationWeek + 1 }}</span
    >
  </template>
</v-card-title>

    
        <v-divider v-if="title"></v-divider>


      <v-card-text class="ma-0 pa-0">
     <!--    <p id="v-step-3" class="px-4">
           {{ planElem.description }}
        </p> -->

      <!--     <Matrix v-if="selected == 0 && planElem.type.includes('matrix') && !workedOut"  ref="matrix"
            :planElem="planElem"
            :planificationweek="planificationWeek"
            :workoutsCompleted="workoutsCompleted"
            :userPlan="user.plan"
          
           />


           <Matrix v-if="selected && planElem.type.includes('matrix') && !workedOut"  ref="matrix"
            :planElem="planElem"
            :userPlan="user.plan"
          
           /> -->
           
          <Simple  v-if="selected !=null && planElem.type == 'listado'" :exercises="planElem.bloques"  ref="simple" />


          <!--
                    <Simple  v-if="selected !=null && planElem.type == 'listado'" :exercises="planElem.bloques"  ref="simple" />
           -->

          <Simple  v-if="selected !=null &&  selected ==0 && planElem.type == 'calendar'" :exercises="exercisesCalendar" :rest="false"  ref="simple" />


          <Simple  v-if="selected !=null &&  selected ==0 && planElem.type == 'schedule'" :exercises="scheduleExercises" :rest="scheduleExercises.lenght==0"  ref="simple" />
          

          <v-card-text v-if="selected!=null && planElem.type =='text'" v-html="planElem.bloques">
          </v-card-text>



<v-card-actions
  v-show="!dialogFinishWorkout"
  v-if="
    !hideFinish &&
    user &&
    user.planification &&
    user.planification == planElem.id &&
    (exercisesCalendar.length > 0 || scheduleExercises.length > 0) 
  "
>
  <v-btn  v-if="isSameDay "
    x-large
    block
    @click="dialogFinishWorkout = true"
    class="glass primary"
    :loading="loadingFinish"
  >
    <v-icon left>
      mdi-check-circle
    </v-icon>
    Marcar completado
  </v-btn>
</v-card-actions>

         



      </v-card-text>

      <v-card-actions v-show="iterIndex != selected">
        <v-btn block elevation="0" @click="selectPlan(iterIndex, planElem)">
          Ver 
        </v-btn>

      </v-card-actions>
    </v-card>



    </v-scroll-x-transition>
          </v-card-text>
      </v-card>
    

    <!-- <v-tour name="myTour" :steps="steps" :options="myOptions">
      <template slot-scope="tour">
        <v-fade-transition>
          <v-step v-if="tour.steps[tour.currentStep]" :key="tour.currentStep" :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep" :next-step="tour.nextStep" :stop="tour.stop" :skip="tour.skip"
            :is-first="tour.isFirst" :is-last="tour.isLast" :labels="tour.labels">
            <template>
              <div slot="actions">
                <v-btn class="mr-2" small @click="skipTour" v-if="tour.isFirst">Saltar intro</v-btn>

                <v-btn class="mr-2" @click="tour.previousStep" v-if="tour.currentStep > 0">
                  <v-icon>
                    mdi-chevron-left
                  </v-icon>
                </v-btn>
                <v-btn @click="tour.nextStep" color="primary" v-if="!tour.isLast">
                  <v-icon v-if="tour.isLast" left>mdi-check</v-icon>
                  <v-icon v-else left>mdi-chevron-right</v-icon>
                  Siguiente</v-btn>

                <v-btn @click="stopTour" v-if="tour.isLast" color="success">
                  <v-icon left>mdi-check</v-icon>
                  Finalizar</v-btn>
              </div>
            </template>
          </v-step>
        </v-fade-transition>
      </template>
    </v-tour> -->

     <v-dialog v-model="dialogFinishWorkout" max-width="400">
            <v-card v-if="user.planification">
          <v-card-title>
            ¡Enhorabuena!
            <v-spacer/>
            <v-btn icon @click="dialogFinishWorkout = false">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
           <v-divider/>
        
          <!--  Training satisfaction  -->
          <v-card-text class="pa-2 text-center" >
              <v-alert text class="mb-0">
              ¿Qué te pareció el entrenamiento? <br/>
               <v-rating class="mt-2" large  v-model="ratingTodayWorkout"  color="yellow" background-color="yellow darken-3"></v-rating>
            </v-alert>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
            
       

            <v-spacer/>
                 <v-btn text @click="dialogFinishWorkout = false">
              Cancelar
            </v-btn>
            <v-btn class="rounded-md"  @click="finishDailyWorkout" color="success" :loading="loadingFinish" :disabled="!ratingTodayWorkout"> 
             
              Confirmar
            </v-btn>
          </v-card-actions>

            </v-card>
          </v-dialog>

  </v-container>
</template>

<script>
import { getFirestore, collection, query, where, getDocs, setDoc, doc, getDoc, addDoc, deleteDoc, updateDoc, runTransaction, Timestamp, serverTimestamp } from "firebase/firestore";

import moment from 'moment'

import ExerciseListItem from "@/components/planifications/view/ExerciseListItem.vue";
import Simple from "@/components/planifications/view/Simple.vue";


import NextDaysCalendar from "@/components/NextDaysCalendar.vue";

/* import Matrix from "@/components/planifications/view/Matrix.vue";
 */


export default {
  props:{
    UserObject: {
      type: Object,
      required: false
    }
  },
  components: {
    ExerciseListItem, Simple, NextDaysCalendar
  },
  name: 'PlanView',
  data() {
    return {
      date: moment().format('YYYY-MM-DD'),
            currentDayIndex: 0,

      dialogFinishWorkout: false,
      ticksLabels: [
          'Malo',
          'Normal',
          'Excelente'
        ],
      ratingTodayWorkout: null,
      filterTags: [],
      selected: null,
      loadingFinish: false,
      activeTab: 0,
      info: null,
      myOptions: {
        useKeyboardNavigation: true,
        labels: {
          buttonSkip: 'Saltear intro',
          buttonPrevious: 'Anterior',
          buttonNext: 'Siguiente',
          buttonStop: 'Finalizar',
        }
      },
      loading: true,
      user: {},
      workoutDoc: {},
      workoutsCompleted: 0,
      workoutPlan: {},
      alert: false,
      showActions: false,
      planificationWeek: null,
      emptyWorkoutDocs: false,
      workedOut: false,
      allRoutines: [],
      reducedTags: [],
      scheduleExercises: [],
      isSameDay: true,
      past7daysworkouts: [],
      past6daysSchedules: [],
      exercisesCalendar: [],
      title:null
    }
  },
  methods: {
    getExercises() {

      console.log('getExercises')
      const selectedDate = moment(this.date, 'YYYY-MM-DD');
      const pastWorkout = this.past7daysworkouts.find(workout => moment(new Date(workout.date.seconds*1000)).isSame(selectedDate, 'day'));


      if (pastWorkout) {
          this.exercisesCalendar = pastWorkout.day_exercises || [];
          this.workoutsCompleted = pastWorkout.workoutDays;
          this.planificationWeek = pastWorkout.week;
          this.title = pastWorkout.title || null;
          this.workedOut = true;
          this.loading = false;
          return pastWorkout.day_exercises || [];
      }else{
        if(!this.isSameDay){
          console.log('not same day')
          this.exercisesCalendar = [];
          this.loading = false;
          return [];
        }
      }

      if(this.selected && this.allRoutines[this.selected].type == 'schedule'){
        return this.scheduleExercises();
      }

      if (this.selected == null || this.allRoutines[this.selected].type !== 'calendar') return [];

      const weekIndex =
      this.planificationWeek %
      this.allRoutines[this.selected].bloques.length;

      const days = this.allRoutines[this.selected].bloques[weekIndex].days;
      if (this.currentDayIndex >= days.length) return [];

      const day = days[this.currentDayIndex];
      this.exercisesCalendar = day.exercises;
      this.title = day.title || null;
      this.loading = false;
      
    },
    async getScheduleExercises() {
        //this looks in the planifications/id/YYYY-MM-DD and gets its data,
        //also checks if there are any warmup or cooldown planifications and adds them to the day exercises

        const db = getFirestore();
        const planificationRef = doc(db, `planifications/${this.user.planification}/workouts/${this.date}`);
        const planificationDoc = await getDoc(planificationRef);
        
        if (planificationDoc.exists()) {
          const planificationData = planificationDoc.data();
          const day = planificationData;
          if(day.warmup && typeof day.warmup == 'string'){
            const warmupRef = doc(db, `planifications/${day.warmup}`);
            const warmupDoc = await getDoc(warmupRef);
            if (warmupDoc.exists()) {
              const warmupData = warmupDoc.data();
              const warmupBlock = warmupData.bloques;
              day.exercises = [...warmupBlock, ...day.exercises];
            }
          }
          if(day.cooldown && typeof day.cooldown == 'string'){
            const cooldownRef = doc(db, `planifications/${day.cooldown}`);
            const cooldownDoc = await getDoc(cooldownRef);
            if (cooldownDoc.exists()) {
              const cooldownData = cooldownDoc.data();
              const cooldownBlock = cooldownData.bloques;
              day.exercises = [...day.exercises, ...cooldownBlock];
            }
          }
          this.scheduleExercises = day.exercises;

          this.title = day.title || null;
        } else {
          this.scheduleExercises = [];
          this.title=null;
        }
        
        this.loading = false;










    },
    async getLast6DaysSchedules() {
      const db = getFirestore();
      const schedules = [];

      for (let i = 1; i <= 6; i++) {
        const momentDate = moment().subtract(i, 'days')
        const scheduleRef = doc(db, `planifications/${this.user.planification}/workouts/${momentDate.format('YYYY-MM-DD')}`);
        const scheduleDoc = await getDoc(scheduleRef);

        if (scheduleDoc.exists()) {
          const scheduleData = scheduleDoc.data();
          schedules.push(momentDate);
        }
      }

      this.past6daysSchedules=  schedules;
    },



    updateDate(date) {
      this.loading=true

      this.$nextTick(() => {
         //check if isSameDay
      if(moment().isSame(moment(date,'YYYY-MM-DD'), 'day')){
        this.isSameDay = true;
      }else{
        this.isSameDay = false;
      }

      if(this.user && this.user.planification && this.allRoutines[0].type == 'schedule'){
        this.getScheduleExercises();
      }else{
        this.getExercises();
      }
      }
      )

      
    
    },
    calculateCurrentDayIndex() {
      if (this.selected == null) {
        this.currentDayIndex = 0;
        return;
      }
      
      const weekIndex = this.planificationWeek % this.allRoutines[this.selected].bloques.length;
      const days = this.allRoutines[this.selected].bloques[weekIndex].days;
      let actualWeek = this.allRoutines[this.selected].bloques[weekIndex]
      let workoutsCompleted = this.workoutsCompleted;


      if (this.workedOut && this.isSameDay &&  this.workoutsCompleted>0) {
        this.workoutsCompleted -= 1;
      }
      this.currentDayIndex = this.workoutsCompleted;




    }

    ,

    stopTour() {
      this.$store.commit('SET_PLANIFICATION_INTRO_SEEN', true)
      this.$tours['myTour'].stop()
    },
    skipTour() {
      this.$store.commit('SET_PLANIFICATION_INTRO_SEEN', true)
      this.$tours['myTour'].skip()
    },
    rpeColor(value) {
      value = parseInt(value)
      if (value === 1) return 'green lighten-2';
      else if (value === 2) return 'green lighten-1';
      else if (value === 3) return 'green ';
      else if (value === 4) return 'green darken-1';
      else if (value == 5) return 'green darken-2';
      else if (value === 6) return 'yellow darken-2';
      else if (value === 7) return 'orange';
      else if (value === 8) return 'orange darken-3';
      else if (value === 9) return 'red';
      else if (value === 10) return 'red darken-3';
    },
    async getUser() {
      let data;
      if(this.$props.UserObject){
        data = this.$props.UserObject
      }else{
        const db = getFirestore()
        let user = this.$store.state.Auth.token.claims.user_id
        const docRef = doc(db, `users/${user}`);
        const docSnap = await getDoc(docRef);
        data = docSnap.data()
      }
      
      data.plan = parseInt(data.plan)

      if (data.plan == 0) {
       
        try{
           const db = getFirestore()
          let scheduleIntervalsRef = doc(db, `configurations/schedule`);
          let scheduleIntervals = await getDoc(scheduleIntervalsRef);
          let scheduleIntervalsData = scheduleIntervals.data()
          let maxDays = 0
          scheduleIntervalsData.Domingo.length > 0 ? maxDays += 1 : null
          scheduleIntervalsData.Lunes.length > 0 ? maxDays += 1 : null
          scheduleIntervalsData.Martes.length > 0 ? maxDays += 1 : null
          scheduleIntervalsData.Miercoles.length > 0 ? maxDays += 1 : null
          scheduleIntervalsData.Jueves.length > 0 ? maxDays += 1 : null
          scheduleIntervalsData.Viernes.length > 0 ? maxDays += 1 : null
          scheduleIntervalsData.Sabado.length > 0 ? maxDays += 1 : null

          data.plan = maxDays
        }catch(e){
          data.plan = 7
        }
       
      }

      this.user = data

      if (this.user.endOfSubscription) {
        let endOfSubscription = new Date(this.user.endOfSubscription.seconds * 1000)
        let today = moment().toDate()
        if (endOfSubscription < today) {
          await this.setAlert('user_subscription_expired', today, null)
          this.$router.push({ name: 'suscripcion' })
        } else {
          //calculate diffs and if less than 5 days, show alert set this.alert=true

          let diff = endOfSubscription.getTime() - today.getTime();
          let days = Math.ceil(diff / (1000 * 3600 * 24));
          if (days <= 5) {
            this.alert = days;
          }
        }
      }


    },
    getPlanificationWeek() {
      if (this.user.planification) {
        const start = moment(this.user.mainWorkout.start, 'YYYY-MM-DD').toDate();
        const now = moment();
        this.planificationWeek = now.diff(start, 'weeks')
      } 
    },
    async getWorkoutsCompleted() {
      const db = getFirestore();
      const user_id = this.$store.state.Auth.token.claims.user_id;
      const workoutsRef = collection(db, `workouts`);

      const today = moment().startOf('day').toDate();
      const sevenDaysAgo = moment().subtract(7, 'days').startOf('day').toDate();

      const startOfWeek = this.getStartOfWeek();
      const endOfWeek = this.getEndOfWeek();

      const q = query(
      workoutsRef,
      where('date', '>=', sevenDaysAgo),
      where('user_id', '==', user_id)
      );  

      const workoutDocs = await getDocs(q);

      if (workoutDocs.empty) {
      this.workoutsCompleted = 0;
      this.workedOut = false;
      } else {
      const workouts = workoutDocs.docs.map(doc => doc.data());
      this.past7daysworkouts = workouts;


      // Filter workouts that are not rest days
      const workoutsWithExercises = workouts.filter(data => {
        return data.day_exercises && data.day_exercises.length > 0;
      });

      // Calculate workouts completed in current week
      const workoutsThisWeek = workoutsWithExercises.filter(data => {
        const workoutDate = data.date.toDate();
        return workoutDate >= startOfWeek && workoutDate <= endOfWeek;
      });

      this.workoutsCompleted = workoutsThisWeek.length;

      // Check if worked out today within the past 7 days
      const isSameDay = workoutsWithExercises.some(data => {
        const workoutDate = moment(new Date(data.date.seconds * 1000))
        return workoutDate.isSame(today, 'day');
      });

      this.workedOut = isSameDay;
      }
    },
    getStartOfWeek() {
      const now = new Date();
      let day = now.getDay();
      const diff = (day === 0 ? -6 : 1); // if it's Sunday, subtract 6, otherwise 1
      const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - day + diff, 0, 0, 0, 0);
      return startOfWeek;
    },
    getEndOfWeek() {
      const startOfWeek = this.getStartOfWeek();
      const endOfWeek = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + 6, 23, 59, 59, 999);
      return endOfWeek;
    },
    async getWorkoutPlan() {
    const db = getFirestore();

    if (!this.user.mainWorkout || !this.user.mainWorkout.plan_id) {
    this.info = 'Sin planificación asignada. Contacta a tu entrenador.';
    return;
    }

    try {
    const planificationsRef = doc(db, `planifications/${this.user.mainWorkout.plan_id}`);
    const planificationDoc = await getDoc(planificationsRef);

    if (!planificationDoc.exists()) {
      this.info = 'Planificación no encontrada. Contacta a tu entrenador.';
      return;
    }

    const data = planificationDoc.data();
    this.allRoutines = [data];

    this.reducedTags = data.tag ? data.tag.sort() : [];

    this.user.planification = this.user.mainWorkout.plan_id;
    this.selected = 0;
    this.getPlanificationWeek();
    } catch (error) {
    console.error('Error fetching workout plan:', error);
    this.info = 'Ocurrió un error al obtener la planificación. Intenta nuevamente.';
    }
  }
,
    startTour() {
      this.$tours['myTour'].start()

    },
   async finishDailyWorkout() {
  this.loadingFinish = true;
  const id = this.$store.state.Auth.token.claims.user_id;
  const date = serverTimestamp();

  let exercises = this.exercisesCalendar;

  if(this.allRoutines[this.selected].type == 'schedule'){
    exercises = this.scheduleExercises;
  }


  const obj = {
    user_id: id,
    user_name: this.user.displayName,
    date,
    workoutDays: this.workoutsCompleted,
    week: this.planificationWeek,
    plan_id: this.user.planification,
    plan_name: this.allRoutines[this.selected].name,
    plan_color: this.allRoutines[this.selected].color,
    day_exercises: exercises,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    rating: this.ratingTodayWorkout,
    dateYYYYMMDD: this.date,
  };

  const db = getFirestore();
  const collectionRef = collection(db, `workouts`);
  await addDoc(collectionRef, obj);

  this.workoutsCompleted += 1;
  this.workedOut = true;

  // Recalculate currentDayIndex after workout completion
  //this.calculateCurrentDayIndex();

  // Notification logic
  if (this.user.plan == this.workoutsCompleted) {
    this.$notify({
      group: 'feedback',
      title: 'Éxito',
      text: 'Objetivo semanal completado',
      type: 'success',
    });
  } else {
    this.$notify({
      group: 'feedback',
      title: 'Éxito',
      text: 'Entrenamiento registrado',
      type: 'success',
    });
  }

  this.loadingFinish = false;
  this.info = null;
  this.dialogFinishWorkout = false;
  this.$vuetify.goTo(0);
},
    selectPlan(iterIndex, planElem) {
      this.selected = iterIndex;
      this.activeTab = 0;
      this.$nextTick(() => {
        this.$vuetify.goTo(0)
      }
      )
    },
    async setAlert(type, date, description) {
      const db = getFirestore();

      let user_id = this.$store.state.Auth.token.claims.user_id

      try {
        const timestampDate = Timestamp.fromDate(date);

        const newAlert = {
          user_id: user_id,
          type: type,
          date: timestampDate,
          description: description,
          seen: false
        };

        await addDoc(collection(db, 'alerts'), newAlert);

        // now add to the alert user subcollection
        const alertRef = collection(db, `users/${user_id}/alerts`);
        await addDoc(alertRef, newAlert);


      } catch (error) {
        console.error("Error adding document: ", error);
      }
    },
    async checkWarmupCooldown(){

      
    const weekIndex =
      this.planificationWeek %
      this.allRoutines[this.selected].bloques.length;

    const days = this.allRoutines[this.selected].bloques[weekIndex].days;
    if (this.currentDayIndex >= days.length) return [];


    const day = days[this.currentDayIndex];

    //check if day has warmup and cooldown and get the planification exercises
    if(day.warmup && typeof day.warmup == 'string'){
      const db = getFirestore();
      const planificationRef = doc(db, `planifications/${day.warmup}`);
      const planificationDoc = await getDoc(planificationRef);
      if (planificationDoc.exists()) {
        const planificationData = planificationDoc.data();
        const warmupBlock = planificationData.bloques;
        day.exercises = [...warmupBlock, ...day.exercises];
      }

    }

    if(day.cooldown && typeof day.cooldown == 'string'){
      const db = getFirestore();
      const planificationRef = doc(db, `planifications/${day.cooldown}`);
      const planificationDoc = await getDoc(planificationRef);
      if (planificationDoc.exists()) {
        const planificationData = planificationDoc.data();
        const cooldownBlock = planificationData.bloques;
        day.exercises = [...day.exercises, ...cooldownBlock];
      }

    }
    }

  },
  computed: {
    hideFinish() {
    return this.workedOut 
  },
    filteredRoutines() {
      return this.allRoutines.filter(routine => {
        if (this.filterTags.length === 0) return true;
        return routine.tag.some(tag => this.filterTags.includes(tag));
      });
    },
  },
  async mounted() {
  this.$vuetify.goTo(0);
  await this.getUser();
  await this.getWorkoutPlan();

  if (this.selected === null && this.allRoutines.length > 0) {
    this.selected = 0;
  }

  this.getPlanificationWeek();
  await this.getWorkoutsCompleted();


  if(this.user && this.user.planification && this.allRoutines[0].type == 'calendar'){
      this.calculateCurrentDayIndex();
      await this.checkWarmupCooldown();

  }

  if(this.user && this.user.planification && this.allRoutines[0].type == 'schedule'){
     await this.getScheduleExercises();
  }else{
    this.getExercises();
  }
  this.getLast6DaysSchedules();


  this.loading = false;
},

}
</script>

<style>

#loaderDays .v-skeleton-loader__avatar {
  width: 40px !important;
  height: 40px !important;
  margin: auto !important;
}

#big .v-skeleton-loader__image {
  height: 90dvh !important;
}

#plan-view .active {
  box-shadow: 0px 0px 8px 3px var(--v-anchor-base) !important;
  padding: 4px !important;
}

.v-step__header {
  background-color: var(--v-anchor-base) !important;
}

.v-step__arrow--dark:before {
  border-color: var(--v-anchor-base) !important;
  background: var(--v-anchor-base) !important;
}

.v-step {
  background: #272727 !important;
  max-width: 288px !important;
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 4px rgb(13 176 237 / 48%) !important;
  -webkit-box-shadow: 0 0 0 4px rgb(13 176 237 / 48%);
}

.evenly-spaced-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* Evenly spaced vertically */
  height: 100%;
  /* Make sure it spans the full height of its parent */
}
</style>


<style scoped></style>